import React from 'react'


function Pricing() {

  
 
  //Pricing card!
  return (
    <div className='pricing-container'>
    <div className='items-pricing-container'>
    <div  className='card-pricing'>
    <h1 >DAILY</h1>
    <h4  >- Dedicated Desk</h4>
    <h4>- Dedicated Desk</h4>
    <h4>- Dedicated Desk</h4>
    <h4>- Dedicated Desk</h4>
    </div>
    <div className='card-pricing'>
    <h1>DAILY</h1>
    <h4>- Dedicated Desk</h4>
    <h4>- Dedicated Desk</h4>
    <h4>- Dedicated Desk</h4>
    <h4>- Dedicated Desk</h4>
    </div>
    <div className='card-pricing'>
    <h1>DAILY</h1>
    <h4>- Dedicated Desk</h4>
    <h4>- Dedicated Desk</h4>
    <h4>- Dedicated Desk</h4>
    <h4>- Dedicated Desk</h4>
    </div>
    
    </div>

    </div>
  )
}

export default Pricing

